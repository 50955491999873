<template>
  <g>
    <svg:style>
      .cls-10, .cls-11, .cls-12, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-3 {
        fill: url(#inox-gradient);
      }

      .cls-4 {
        fill: url(#glass-pattern);
      }

      .cls-5 {
        fill: url(#inox-gradient-2);
      }

      .cls-6 {
        fill: url(#glass-pattern);
      }

      .cls-7 {
        fill: url(#inox-gradient-3);
      }

      .cls-8 {
        fill: url(#glass-pattern);
      }

      .cls-9 {
        fill: url(#inox-gradient-4);
      }

      .cls-10 {
        fill: url(#glass-pattern);
      }

      .cls-11 {
        fill: url(#inox-gradient-5);
      }

      .cls-12 {
        fill: url(#glass-pattern);
      }

    </svg:style>
    <linearGradient id="inox-gradient" 
      :x1="doorLeftWidth1 + 73.32" 
      :y1="doorTopHeight1 + 150.23" 
      :x2="doorLeftWidth1 + 101.56" 
      :y2="doorTopHeight1 + 150.23" 
      gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="glass-gradient" 
      :x1="doorLeftWidth1 + 87.44" 
      :y1="doorTopHeight1 + 159.47" 
      :x2="doorLeftWidth1 + 87.44" 
      :y2="doorTopHeight1 + 141.1" 
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="inox-gradient-2" data-name="inox" 
      :x1="doorLeftWidth1 + 67.22" 
      :y1="doorTopHeight1 + 106.95" 
      :x2="doorLeftWidth1 + 99.93" 
      :y2="doorTopHeight1 + 106.95" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="glass-gradient-2" data-name="glass-gradient" 
      :x1="doorLeftWidth1 + 83.58" 
      :y1="doorTopHeight1 + 117.64" 
      :x2="doorLeftWidth1 + 83.58" 
      :y2="doorTopHeight1 + 96.37" 
      xlink:href="#glass-gradient"/>
    <linearGradient id="inox-gradient-3" data-name="inox" 
      :x1="doorLeftWidth1 + 54.32" 
      :y1="doorTopHeight1 + 64.99" 
      :x2="doorLeftWidth1 + 90.35" 
      :y2="doorTopHeight1 + 64.99" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="glass-gradient-3" data-name="glass-gradient" 
      :x1="doorLeftWidth1 + 72.35" 
      :y1="doorTopHeight1 + 76.71" 
      :x2="doorLeftWidth1 + 72.35" 
      :y2="doorTopHeight1 + 53.37" 
      xlink:href="#glass-gradient"/>
    <linearGradient id="inox-gradient-4" data-name="inox" 
      :x1="doorLeftWidth1 + 67.22" 
      :y1="doorTopHeight1 + 193.55" 
      :x2="doorLeftWidth1 + 99.93" 
      :y2="doorTopHeight1 + 193.55" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="glass-gradient-4" data-name="glass-gradient" 
      :x1="doorLeftWidth1 + 83.58" 
      :y1="doorTopHeight1 + 204.13" 
      :x2="doorLeftWidth1 + 83.58" 
      :y2="doorTopHeight1 + 182.86" 
      xlink:href="#glass-gradient"/>
    <linearGradient id="inox-gradient-5" data-name="inox" 
      :x1="doorLeftWidth1 + 54.24" 
      :y1="doorTopHeight1 + 235.51" 
      :x2="doorLeftWidth1 + 90.44" 
      :y2="doorTopHeight1 + 235.51" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="glass-gradient-5" data-name="glass-gradient" 
      :x1="doorLeftWidth1 + 72.36" 
      :y1="doorTopHeight1 + 247.23" 
      :x2="doorLeftWidth1 + 72.36" 
      :y2="doorTopHeight1 + 223.69" 
      xlink:href="#glass-gradient"/>
    <linearGradient id="handle-gradient" 
      :x1="doorLeftWidth1 + 11.29" 
      :y1="doorTopHeight1 + 150.25" 
      :x2="doorLeftWidth1 + 16.94" 
      :y2="doorTopHeight1 + 150.25" 
      xlink:href="#inox-gradient"/>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>

    <g id="S21">
      <polyline id="inox" 
                v-if="showInox"
                data-name="inox" 
                class="cls-3" 
                :points="`${doorLeftWidth1 + 73.32} ${doorTopHeight1 + 164.36} ${doorLeftWidth1 + 101.56} ${doorTopHeight1 + 164.36} ${doorLeftWidth1 + 101.56} ${doorTopHeight1 + 136.1} ${doorLeftWidth1 + 73.32} ${doorTopHeight1 + 136.1} ${doorLeftWidth1 + 73.32} ${doorTopHeight1 + 164.36}`"/>
      <polyline filter="url(#inset-shadow)" id="glass" 
                class="cls-4" 
                :points="`${doorLeftWidth1 + 96.62} ${doorTopHeight1 + 141.1} ${doorLeftWidth1 + 78.27} ${doorTopHeight1 + 141.1} ${doorLeftWidth1 + 78.27} ${doorTopHeight1 + 159.47} ${doorLeftWidth1 + 96.62} ${doorTopHeight1 + 159.47} ${doorLeftWidth1 + 96.62} ${doorTopHeight1 + 141.1}`"/>
      <polyline id="inox-2" 
                v-if="showInox"
                data-name="inox" 
                class="cls-5" 
                :points="`${doorLeftWidth1 + 72.12} ${doorTopHeight1 + 123.32} ${doorLeftWidth1 + 99.93} ${doorTopHeight1 + 118.4} ${doorLeftWidth1 + 95.03} ${doorTopHeight1 + 90.59} ${doorLeftWidth1 + 67.22} ${doorTopHeight1 + 95.49} ${doorLeftWidth1 + 72.12} ${doorTopHeight1 + 123.32}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-2" 
                data-name="glass" 
                class="cls-6" 
                :points="`${doorLeftWidth1 + 91.03} ${doorTopHeight1 + 96.37} ${doorLeftWidth1 + 72.95} ${doorTopHeight1 + 99.56} ${doorLeftWidth1 + 76.14} ${doorTopHeight1 + 117.64} ${doorLeftWidth1 + 94.22} ${doorTopHeight1 + 114.45} ${doorLeftWidth1 + 91.03} ${doorTopHeight1 + 96.37}`"/>
      <polyline id="inox-3" 
                v-if="showInox"
                data-name="inox" 
                class="cls-7" 
                :points="`${doorLeftWidth1 + 64.16} ${doorTopHeight1 + 83} ${doorLeftWidth1 + 90.17} ${doorTopHeight1 + 73.53} ${doorLeftWidth1 + 90.29} ${doorTopHeight1 + 73.44} ${doorLeftWidth1 + 90.35} ${doorTopHeight1 + 73.31} ${doorLeftWidth1 + 90.34} ${doorTopHeight1 + 73.16} ${doorLeftWidth1 + 80.87} ${doorTopHeight1 + 47.15} ${doorLeftWidth1 + 80.73} ${doorTopHeight1 + 46.99} ${doorLeftWidth1 + 80.52} ${doorTopHeight1 + 46.98} ${doorLeftWidth1 + 54.51} ${doorTopHeight1 + 56.45} ${doorLeftWidth1 + 54.38} ${doorTopHeight1 + 56.53} ${doorLeftWidth1 + 54.32} ${doorTopHeight1 + 56.66} ${doorLeftWidth1 + 54.34} ${doorTopHeight1 + 56.81} ${doorLeftWidth1 + 63.8} ${doorTopHeight1 + 82.83} ${doorLeftWidth1 + 63.88} ${doorTopHeight1 + 82.94} ${doorLeftWidth1 + 64.02} ${doorTopHeight1 + 83.01} ${doorLeftWidth1 + 64.16} ${doorTopHeight1 + 83}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-3" 
                data-name="glass" 
                class="cls-8" 
                :points="`${doorLeftWidth1 + 84.03} ${doorTopHeight1 + 70.25} ${doorLeftWidth1 + 77.94} ${doorTopHeight1 + 53.53} ${doorLeftWidth1 + 77.79} ${doorTopHeight1 + 53.38} ${doorLeftWidth1 + 77.58} ${doorTopHeight1 + 53.37} ${doorLeftWidth1 + 60.86} ${doorTopHeight1 + 59.45} ${doorLeftWidth1 + 60.74} ${doorTopHeight1 + 59.54} ${doorLeftWidth1 + 60.67} ${doorTopHeight1 + 59.67} ${doorLeftWidth1 + 60.69} ${doorTopHeight1 + 59.82} ${doorLeftWidth1 + 66.78} ${doorTopHeight1 + 76.54} ${doorLeftWidth1 + 66.92} ${doorTopHeight1 + 76.7} ${doorLeftWidth1 + 67.13} ${doorTopHeight1 + 76.71} ${doorLeftWidth1 + 83.85} ${doorTopHeight1 + 70.62} ${doorLeftWidth1 + 84.02} ${doorTopHeight1 + 70.48} ${doorLeftWidth1 + 84.03} ${doorTopHeight1 + 70.25}`"/>
      <polyline id="inox-4"
                v-if="showInox" 
                data-name="inox" 
                class="cls-9" 
                :points="`${doorLeftWidth1 + 99.93} ${doorTopHeight1 + 182.09} ${doorLeftWidth1 + 72.12} ${doorTopHeight1 + 177.19} ${doorLeftWidth1 + 67.22} ${doorTopHeight1 + 205} ${doorLeftWidth1 + 95.03} ${doorTopHeight1 + 209.91} ${doorLeftWidth1 + 99.93} ${doorTopHeight1 + 182.09}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-4" 
                data-name="glass" 
                class="cls-10" 
                :points="`${doorLeftWidth1 + 72.95} ${doorTopHeight1 + 200.94} ${doorLeftWidth1 + 91.03} ${doorTopHeight1 + 204.13} ${doorLeftWidth1 + 94.22} ${doorTopHeight1 + 186.04} ${doorLeftWidth1 + 76.14} ${doorTopHeight1 + 182.86} ${doorLeftWidth1 + 72.95} ${doorTopHeight1 + 200.94}`"/>
      <polyline id="inox-5" 
                v-if="showInox"
                data-name="inox" 
                class="cls-11" 
                :points="`${doorLeftWidth1 + 90.44} ${doorTopHeight1 + 227.07} ${doorLeftWidth1 + 63.89} ${doorTopHeight1 + 217.41} ${doorLeftWidth1 + 54.24} ${doorTopHeight1 + 243.96} ${doorLeftWidth1 + 80.78} ${doorTopHeight1 + 253.61} ${doorLeftWidth1 + 90.44} ${doorTopHeight1 + 227.07}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-5" 
                data-name="glass" 
                class="cls-12" 
                :points="`${doorLeftWidth1 + 60.59} ${doorTopHeight1 + 240.95} ${doorLeftWidth1 + 77.85} ${doorTopHeight1 + 247.23} ${doorLeftWidth1 + 84.12} ${doorTopHeight1 + 229.97} ${doorLeftWidth1 + 66.87} ${doorTopHeight1 + 223.69} ${doorLeftWidth1 + 60.59} ${doorTopHeight1 + 240.95}`"/>
      <line id="inox-6" data-name="inox" class="cls-2" :x1="`${doorLeftWidth1 + 76.3}`" :y1="`${doorTopHeight1 + 250.87}`" :x2="`${doorLeftWidth1 + 76.56}`" :y2="`${doorTopHeight1 + 250.7}`"/>
      <line id="inox-7" data-name="inox" class="cls-2" :x1="`${doorLeftWidth1 + 76.3}`" :y1="`${doorTopHeight1 + 250.87}`" :x2="`${doorLeftWidth1 + 76.56}`" :y2="`${doorTopHeight1 + 250.7}`"/>
      <line id="inox-8" data-name="inox" class="cls-2" :x1="`${doorLeftWidth1 + 79.56}`" :y1="`${doorTopHeight1 + 255.82}`" :x2="`${doorLeftWidth1 + 79.82}`" :y2="`${doorTopHeight1 + 255.65}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
